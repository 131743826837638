import { Component } from '@angular/core';

@Component({
    selector: 'app-how-to-order',
    standalone: true,
    imports: [],
    templateUrl: './how-to-order.component.html',
    styleUrl: './how-to-order.component.scss'
})
export class howToOrderComponent {

}
