<nav>
    <img src="./../../../../assets/img/sakura_logo.png" alt="">
    <div class="links_and_phone">
        <a href="#menu">MENU</a>
        <a href="#menu">LOCATION</a>
        <a href="#how_to_order">ABOUT</a>
        <div class="phone_btn">
            <img src="./../../../../assets/img/phone.png" alt="">
            <a href="">0176 55987841</a>
        </div>
    </div>
</nav>
