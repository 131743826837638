import { Component } from '@angular/core';

@Component({
  selector: 'app-navebar',
  standalone: true,
  imports: [],
  templateUrl: './navebar.component.html',
  styleUrl: './navebar.component.scss'
})
export class NavebarComponent {

}
