<section id="menu">
    <h2>OUR MENU</h2>
    <div class="element">
        <img src="./../../../assets/img/R1.png" alt="">
        <div class="bsp_for_food">
            <h3>Tonkatsu Ramen</h3>
            <table>
                <tr>
                    <td>CHICKEN</td>
                    <td>11,95</td>
                </tr>
                <tr>
                    <td>BEEF</td>
                    <td>12,95</td>
                </tr>
                <tr>
                    <td>SHRIMP</td>
                    <td>13,95</td>
                </tr>
            </table>
        </div>
    </div>
</section>